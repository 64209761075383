export const linconnu = [
    {
        image: "/images/cours.webp",
        title: "Linconnu",
        alt: "Linconnu"
    },
    {
        image: "/images/cardOne.webp",
        title: "Artiste prestidigitateur",
        alt: "Linconnu: artiste prestidigitateur"
    },
    {
        image: "/images/bio.webp",
        title: "Cours & prestations",
        alt: "Linconnu propose des cours et prestations de magie"
    },
]
